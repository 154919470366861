<div *ngIf="vitals.checkSession()">
<h3>LCG Health Check</h3>
<h4>Step 1: Identification</h4>
<hr>
<div class="row">
  <div class="col-md-5 text-center">
    <h5>Visitors, enter your Name.</h5>
    <p>If you are a visitor, please click the button below.</p>
    <button class="btn btn-success btn-block" routerLink="/guest" (click)="vitals.setManual(true)">Click Here to Enter Your Name as a Guest</button>
  </div>
  <div class="col-md-2 text-center">
    <h2>or</h2>
  </div>
  <div class="col-md-5 text-center">
    <h5>Employees, Enter your Username.</h5>
    <p>If you are an employee, please click the button below.</p>
    <button class="btn btn-success btn-block" routerLink="/manualEntry" (click)="vitals.setManual(true)">Click Here to Enter Your Name as an Employee</button>
  </div>
</div>
</div>
<div *ngIf="!vitals.checkSession()">
  <app-failed-check></app-failed-check>
</div>
