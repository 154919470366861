<div *ngIf="vitals.checkSession()">
  <h3>LCG Health Check</h3>
  <h4>Step 1: Identification <span *ngIf="vitals.getManual()">(Guest Entry)</span></h4>
  <hr>
  <h5 class="text-center">Please enter your Name.</h5>
  <p>Please enter your full, legal name in the boxes provided below.</p>
  <input class="form-control" style="text-align: center" type="text" (change)="compileName($event, 'firstName')" placeholder="Begin Typing Your First Name Here...">
  <br>
  <input class="form-control" style="text-align: center" type="text" (change)="compileName($event, 'lastName')" placeholder="Begin Typing Your Last Name Here...">
  <br>
  <button class="btn btn-success btn-block" routerLink="/identify">Please Click Here to Continue</button>
</div>
<div *ngIf="!vitals.checkSession()">
  <app-failed-check></app-failed-check>
</div>
