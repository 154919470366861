import { Component, OnInit } from '@angular/core';
import {VitalsService} from "../vitals.service";
import {QuestionInput} from '../question-input';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  vitals: VitalsService
  constructor(vitals: VitalsService) {
    this.vitals = vitals;
  }

  ngOnInit(): void {
  }

  setTempQ($event: Event, x: number) {
    var tem = x;
    var tBool = false;
    if(tem === 0){
      tBool = false;
    }
    if(tem === 1){
      tBool = true;
    }
    this.vitals.setTempQ(tBool);
  }

  allQsAnswered(): boolean {
    let i: number = 0;

    if(i < 0) {
      return false;
    }
    if( ( (!this.vitals.showHideToggle && this.vitals.answeredQuestions.length === this.vitals.showHideToggleIndex) && this.vitals.answeredSymptoms.length === this.vitals.cdcSymptoms.length) || (this.vitals.answeredQuestions.length === this.vitals.questions.length) && this.vitals.answeredSymptoms.length === this.vitals.cdcSymptoms.length){
      return true;
    }
    return false;

  }

  setAddtlQ($event: Event, i: number, x: number) {
    let v = this.vitals.questions[i];
    let ans = x;
    if(this.vitals.showHideToggleIndex === i+1){
      if(ans === 1){
        this.vitals.showHideToggle = false;
      }else{
        this.vitals.showHideToggle = true;
      }
    }

    let tAns = "Yes";
    if(ans === 1){
      tAns = "Yes"
    }else{
      tAns = "No"
    }
    //console.log(tAns);
    let tempQ = ({questionText: v, answer: tAns, value: ans, indexNum: i} as unknown as QuestionInput)
    let tBool = true;
    for (let j = 0; j < this.vitals.answeredQuestions.length; j++) {
      if(v === this.vitals.answeredQuestions[j].questionText){
        this.vitals.answeredQuestions[j] = tempQ;
        tBool = false;
        console.log("Stored Old Question");
      }
    }
    if(tBool){
      this.vitals.answeredQuestions.push(tempQ);
      console.log("Stored New Question");
    }
  }

  setVital(i: number, number: number) {
    let v = this.vitals.cdcSymptoms[i];
    let ans = number;
    let tAns = "Yes";
    if(ans === 1){
      tAns = "Yes"
    }else{
      tAns = "No"
    }
    //console.log(tAns);
    let tempQ = ({questionText: v, answer: tAns, value: ans, indexNum: i} as unknown as QuestionInput)
    let tBool = true;
    for (let j = 0; j < this.vitals.answeredSymptoms.length; j++) {
      if(v === this.vitals.answeredSymptoms[j].questionText){
        this.vitals.answeredSymptoms[j] = tempQ;
        tBool = false;
        console.log("Stored Old Symptom");
      }
    }
    if(tBool){
      this.vitals.answeredSymptoms.push(tempQ);
      console.log("Stored New Symptom");
    }
  }
}
