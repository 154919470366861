import { Component, OnInit } from '@angular/core';
import {VitalsService} from "../vitals.service";
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.css']
})
export class FrontComponent implements OnInit {

  private http2: HttpClient;

  constructor(private http: HttpClient) {
    this.vitals = new VitalsService(http);
    this.http2 = http;
  }

  vitals: VitalsService;
  checkSession() {
    this.vitals = new VitalsService(this.http2);
    if(this.vitals.initSession()){
      return true;
    }else{
      return false;
    }

  }
  ngOnInit(): void {
  }

}
