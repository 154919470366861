import { Component, OnInit } from '@angular/core';
import {VitalsService} from "../vitals.service";

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {

  vitals: VitalsService;
  constructor(vitals: VitalsService) {
    this.vitals = vitals;
  }

  ngOnInit(): void {
  }

  setManual() {
    this.vitals.manual = true;
  }
}
