<div class="row">
  <div class="offset-md-2"></div>
  <div class="col-md-7">
    <div class="card">
      <div class="card-body">
        <router-outlet></router-outlet>
        <hr>
        <p class="text-center">&copy; 2021, Linearizer Communications Group<br>Version 2.2.0<br>All information shared in this system is sent securely to HR and is not accessible by any third party.<br>{{momentSet()}}</p>
      </div>
    </div>
  </div>
</div>
