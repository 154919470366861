import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FrontComponent} from "./front/front.component";
import {StartComponent} from "./start/start.component";
import {ManualEntryComponent} from "./manual-entry/manual-entry.component";
import {IdentifyComponent} from "./identify/identify.component";
import {QuestionsComponent} from "./questions/questions.component";
import {FinalizeComponent} from "./finalize/finalize.component";
import {GuestComponent} from './guest/guest.component';


const routes: Routes = [
  {path: 'finalize', component: FinalizeComponent},
  {path: 'identify', component: IdentifyComponent},
  {path: 'manualEntry', component: ManualEntryComponent},
  {path: 'start', component: StartComponent},
  {path: 'questions', component: QuestionsComponent},
  {path: 'guest', component: GuestComponent},
  {path: '', component: FrontComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
