<div *ngIf="vitals.checkSession()" #screen>
  <h3>LCG Health Check</h3>
  <h4>Step 4: Results</h4>
  <hr>
  <p></p>
  <h1>{{vitals.getUsername()}}</h1>
  <h2>{{vitals.momentGet()}}</h2>
  <div *ngIf="vitals.isDone">
  <div *ngIf="vitals.canEnter" style="color: green; font-size: 150px" class="text-center">
    ✅
    <h2>Thank you. You may enter the building.</h2>
  </div>
  <div *ngIf="!vitals.canEnter" style="color: red; font-size: 150px" class="text-center">
    ❌
    <h2>Please stay home today and inform your manager.</h2>
  </div>
  <button class="btn btn-block btn-success" (click)="downloadCanvas()">Download this Page as an Image</button>
  </div>
  <div *ngIf="!vitals.isDone">
    <h2>Please wait, we are reviewing your answers.</h2>
  </div>
</div>
<div style="display:none">
<img #canvas>
  <a #downloadLink></a>
</div>
<div *ngIf="!vitals.checkSession()">
  <app-failed-check></app-failed-check>
</div>
