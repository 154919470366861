<h3>LCG Health Check</h3>
<h4>Introduction</h4>
<hr>
<p>Welcome. This Health Check is part of Linearizer Communications Group's goal to help stop the spread of COVID-19.</p><br>
<p>We (Linearizer Communications Group) are requiring the screening of all employees/visitors prior to the arrival at our facility. We are doing this to help keep you and all of us healthy. PLEASE carefully review the instructions and complete the REQUIRED form every day before work.  To do this, you need to check your temperature regularly.<br><br>

  Your response will be kept strictly confidential. HR and your manager will confirm each day that you completed the form.
  If you do not meet the criteria below every day, you must stay home and notify your manager and/or HR. Please do not enter the facility. Not answering accurately will place you and your fellow employees at risk.</p>
<h4>Instructions</h4>
<p>Once you click Start, you will be asked to provide us your name if you are a Guest or your Employee User Name to identify you. Next, you will answer some basic health questions to deem if you are safe to enter the Facility. It is imperative that you answer all questions truthfully and accurately, and follow the on-screen guidance at all times.</p>
<p>We will require the following things once you begin, so please take a moment to ensure you have: 1) Your most recent temperature reading; 2) Personal Protective Equipment to help stop the spread of COVID-19.</p>
<button class="btn btn-success btn-block" routerLink="/start">Start</button>
<p *ngIf="vitals.initSession()" style="display: none">Session Salt: {{vitals.getSession()}}</p>
