import { Injectable } from '@angular/core';
import {QuestionInput} from './question-input';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {CovidResult} from './covid-result';

@Injectable({
  providedIn: 'root'
})
export class VitalsService {

  public badgeID: string;
  public username: string;
  public tempQ: boolean;
  public manual: boolean;
  public guest: boolean;
  public showHideToggle: boolean;
  public showHideToggleIndex: number;
  public questions: Array<string>;
  public questionsHelp: Array<string>;
  public cdcSymptoms: Array<string>;
  public answeredQuestions: QuestionInput[];
  public answeredSymptoms: QuestionInput[];
  public finalizedObj: Object;
  public canEnter: boolean = true;
  public isDone: boolean = false;
  safeTemp: string = "100.4ºF";
  constructor(private http: HttpClient) {
    //todo: make this a text/TS file -MS
    this.questions = [
      "Have you tested positive for COVID-19 within the past 14 days or are you awaiting the results of a COVID-19 test due to potential exposure?",
      "Are you fully vaccinated (greater than 2 weeks past the second dose in a 2-dose series or following receipt of the first dose in a single-dose vaccine) or have you recovered from a documented COVID-19 infection in the last 3 months?",
      "In the last 14 days, have you been in close physical contact with someone who is known to have a laboratory-confirmed case of COVID-19 or has any symptoms consistent with COVID-19?",
    ]
    this.questionsHelp = [
      "If you answered “YES” to questions 1 or 2, absent any agreement with the Company to the contrary, please do not come into the workplace or visit in-person with any clients or employees.  This includes <u>**fully vaccinated individuals**</u>.",
      "",
      "“Close physical contact” means being within six feet of an infected/symptomatic person for a cumulative total of 15 minutes or more over a 24-hour period starting from 48 hours before illness onset (or, for asymptomatic individuals, 48 hours prior to test specimen collection).",
    ]
    this.showHideToggleIndex = 2;
    this.showHideToggle = false;
    this.cdcSymptoms = [
      "fever, chills, cough, shortness of breath or difficulty breathing, fatigue, muscle or body aches, headaches, new loss of taste or smell, sore throat, congestion or runny nose, nausea or vomiting, or diarrhea."
    ]
    this.clearAnswers();
  }

  momentGet() {
    return moment().format("MMMM DD, YYYY HH:mm:ss");
  }

  public momentSet() {
    return moment().format("MMDDYYYY-HHmmss");
  }

  public clearAnswers(){
    this.answeredQuestions = [];
    this.answeredSymptoms = [];
    this.isDone = false;
    this.finalizedObj = {};
    this.canEnter = true;
  }

  public setTempQ(b: boolean): void{
    localStorage.setItem("tempQ", String(b));
  }

  public setManual(b: boolean): void{
    localStorage.setItem("manual", String(b));
  }
  public getManual(): boolean{
    if(localStorage.getItem("manual") === "true"){return true}else{return false}
    return false;
  }
  public getTempQ(): boolean{
    if(localStorage.getItem("tempQ") === "true"){return true}else{return false}
    return false;
  }

  getRandomId() {
    var S4 = function() {
      return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
  }

  getSession(){
    return sessionStorage.getItem("session");
  }
  checkSession(){
    if(sessionStorage.getItem("session")){
      return true;
    }
    return false;
  }


  clearSession(){
    sessionStorage.clear();
  }

  initSession() {
    // generate a session id
    var sid = this.getRandomId();
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.setItem("session", sid.toString());
    return true;
  }

  getUsername(): string{
    return localStorage.getItem("username");
  }

  setUsername(s: string) {
    localStorage.setItem("username", s);
  }

  finalize() {
    this.finalizedObj = {
      name: this.getUsername(),
      tempQ: this.getTempQ(),
      vitals: {data: this.answeredSymptoms},
      questions: {data: this.answeredQuestions}
    };
    this.http.post<CovidResult>("https://key.linearizer.com/covid/app/post/", this.finalizedObj)
      .subscribe(data => {
        this.canEnter = data.canEnter;
        this.isDone = true;
      })
  }
}
