import { Component } from '@angular/core';
import {VitalsService} from "./vitals.service";
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'onboarding';
  momentSet() {
    return moment().format("MMMM DD, YYYY HH:mm:ss");
  }
  ngOnInit(){
    window.onbeforeunload = function(){
      sessionStorage.clear();
    }
  }
}
