import { Component, OnInit } from '@angular/core';
import {VitalsService} from '../vitals.service';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.css']
})
export class GuestComponent implements OnInit {

  vitals: VitalsService;
  firstName: string;
  lastName: string;
  constructor(vitals: VitalsService) {
    this.vitals = vitals;
  }

  ngOnInit(): void {
  }

  setUsername($event: Event) {
    this.vitals.setUsername(($event.target as HTMLInputElement).value);
  }

  compileName($event: Event, type: string) {
    this.vitals.guest = true;
    if(type === "firstName"){
      this.firstName = ($event.target as HTMLInputElement).value;
    }else if(type === "lastName"){
      this.lastName = ($event.target as HTMLInputElement).value;
    }
    this.vitals.setUsername(this.firstName + " " + this.lastName + " (Guest)")
  }
}
