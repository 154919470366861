<div *ngIf="vitals.checkSession()">
<h3>LCG Health Check</h3>
<h4>Step 1: Identification <span *ngIf="vitals.getManual()">(Employee Entry)</span></h4>
<hr>
<h5 class="text-center">Please enter your username.</h5>
  <img src="assets/sample-user-example.png" class="img-fluid">
<p>Generally, your username is your first initial followed by your last name. Your username can be found under your last name on your Employee Identification Card.</p>
<input class="form-control" style="text-align: center" type="text" (change)="setUsername($event)" placeholder="Enter Your Username Here (ex: JExample)">
  <br>
  <button class="btn btn-success btn-block" routerLink="/identify">Please Click Here to Continue</button>
</div>
<div *ngIf="!vitals.checkSession()">
  <app-failed-check></app-failed-check>
</div>
