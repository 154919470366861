import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {VitalsService} from "../vitals.service";
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-regular-svg-icons';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-finalize',
  templateUrl: './finalize.component.html',
  styleUrls: ['./finalize.component.css']
})
export class FinalizeComponent implements OnInit {
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  vitals: VitalsService;
  constructor(vitals: VitalsService) {
    this.vitals = vitals;
  }

  ngOnInit(): void {
    this.vitals.finalize();
  }


  downloadCanvas(){
    html2canvas(document.body).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = this.vitals.momentSet()+'-export.png';
      this.downloadLink.nativeElement.click();
    });
  }

}
