import { Component, OnInit } from '@angular/core';
import {VitalsService} from "../vitals.service";

@Component({
  selector: 'app-identify',
  templateUrl: './identify.component.html',
  styleUrls: ['./identify.component.css']
})
export class IdentifyComponent implements OnInit {

  vitals: VitalsService;
  certify: boolean;
  constructor(vitals: VitalsService) {
    this.vitals = vitals;
  }

  ngOnInit(): void {
  }

  submitData() {
    if(this.vitals.getManual() === true){
      // its a manual check, cant submit data
      console.log("true");
      if(this.vitals.getUsername() === null){
        return "Please click back on your browser and enter your username.";
      }
      this.certify = true;
      return "Please follow the instructions below.";
    }else{
      //its a badge scan, submit for verification.
    }
  }
}
