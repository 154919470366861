import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FrontComponent } from './front/front.component';
import { StartComponent } from './start/start.component';
import {VitalsService} from "./vitals.service";
import { ManualEntryComponent } from './manual-entry/manual-entry.component';
import { IdentifyComponent } from './identify/identify.component';
import { FailedCheckComponent } from './failed-check/failed-check.component';
import { QuestionsComponent } from './questions/questions.component';
import { FinalizeComponent } from './finalize/finalize.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { GuestComponent } from './guest/guest.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {HttpClientModule} from '@angular/common/http';
import {MarkdownModule} from 'ngx-markdown';

@NgModule({
  declarations: [
    AppComponent,
    FrontComponent,
    StartComponent,
    ManualEntryComponent,
    IdentifyComponent,
    FailedCheckComponent,
    QuestionsComponent,
    FinalizeComponent,
    GuestComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgbModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        FontAwesomeModule,
        HttpClientModule,
        MarkdownModule.forRoot()
    ],
  providers: [VitalsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
