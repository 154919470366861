import { Component, OnInit } from '@angular/core';
import {VitalsService} from "../vitals.service";

@Component({
  selector: 'app-manual-entry',
  templateUrl: './manual-entry.component.html',
  styleUrls: ['./manual-entry.component.css']
})
export class ManualEntryComponent implements OnInit {

  vitals: VitalsService;
  constructor(vitals: VitalsService) {
    this.vitals = vitals;
  }

  ngOnInit(): void {
  }

  setUsername($event: Event) {
    this.vitals.setUsername(($event.target as HTMLInputElement).value);
    this.vitals.guest = false;
  }
}
