<div *ngIf="vitals.checkSession()">
  <h3>LCG Health Check</h3>
  <h4>Step 2: Identification</h4>
  <hr>
  <p [innerHTML]="submitData()">We are submitting your information.. Please give us a moment..</p>
  <p *ngIf="certify"><button class="btn btn-success btn-block text-white" routerLink="/questions">I certify that I am {{this.vitals.getUsername()}} or can submit this information on their behalf.</button></p>
</div>
<div *ngIf="!vitals.checkSession()">
  <app-failed-check></app-failed-check>
</div>
