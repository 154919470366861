<div *ngIf="vitals.checkSession()">
<h3>LCG Health Check</h3>
<h4>Part 3: Questions</h4>
<hr>
<h5>Instructions</h5>
<p>Please answer all questions truthfully in regards to {{this.vitals.getUsername()}}.</p>
<hr>
  <!--
<h5>Question 1: Have you had any signs or symptoms of a fever today such as chills, sweats, felt “feverish” or have a temperature of {{this.vitals.safeTemp}} or greater?</h5>
  <div class="row">
    <div class="col-md-6">
      <input type="radio" name="q-ans-temp" value="1" (change)="setTempQ($event, 1)"><label>Yes, my temperature has been above {{this.vitals.safeTemp}}</label><br>
    </div>
    <div class="col-md-6">
      <input type="radio" name="q-ans-temp" value="0" (change)="setTempQ($event,0)"><label>No, my temperature has been below {{this.vitals.safeTemp}}</label>
    </div>
  </div>
  <hr>
  -->
  <h5>Question 1: In the past 10 days, have you had the onset of any COVID-19 symptoms? The CDC symptoms list changes from time to time. Please refer to this full list to be sure you do not have any symptoms:  <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html">https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html</a></h5>
  <table class="table table-striped">
    <tr>
      <th>Symptoms</th>
      <th>&nbsp;</th>
    </tr>
    <tr *ngFor="let v of vitals.cdcSymptoms; index as i;">
      <td>{{v}}</td>
      <td><input type="radio" name="symptom-ans-{{i}}" value="1" (change)="setVital(i,1)"><label>Yes</label><br>
      <input type="radio" name="symptom-ans-{{i}}" value="0" (change)="setVital(i,0)"><label>No</label></td>
    </tr>
  </table>
  <hr>
  <div *ngFor="let q of vitals.questions; index as i">
    <div *ngIf="(i >= vitals.showHideToggleIndex && vitals.showHideToggle) || (i < vitals.showHideToggleIndex)">
      <h5>Question {{i+2}}: {{q}}</h5>
        <div class="row">
          <div class="col-md-6">
            <input type="radio" name="q-ans-{{i}}" value="1" (change)="setAddtlQ($event, i, 1)"><label>Yes</label><br>
          </div>
          <div class="col-md-6">
            <input type="radio" name="q-ans-{{i}}" value="0" (change)="setAddtlQ($event, i, 0)"><label>No</label>
          </div>
        </div>
      <i><markdown [data]="vitals.questionsHelp[i]"></markdown></i>
    </div>
    <hr>
  </div>

<br>
  <button *ngIf="!allQsAnswered()" class="btn btn-success disabled btn-block">Please Answer all Questions before Proceeding.</button>
  <button *ngIf="allQsAnswered()" class="btn btn-success btn-block" routerLink="/finalize">Submit Questionnaire</button>
</div>
<div *ngIf="!vitals.checkSession()">
  <app-failed-check></app-failed-check>
</div>
